<template>
  <express :dataAuthority="true" />
</template>

<script>
import express from '@/views/express-track/component'
export default {
  components: {
    express
  }
}
</script>
